import React from "react";
import "./style.scss";
import { useSetRecoilState } from "recoil";
import { joinModalState } from "../../recoil/atoms";
import { isIOS, isMobile } from "react-device-detect";
import logoApple from "../../Images/apple_logo_32px.svg";

function AppStore() {
  const setJoinModalState = useSetRecoilState(joinModalState);
  return (
    <section className="app-store-section">
      <h3 className="app-store-section-h">
        Step up your game with automated workout tracking
      </h3>
      <p className="app-store-section-t">
        If you’re still manually logging your reps at the gym, it’s time to join
        the future of Fitness. Train Fitness does the heavy lifting, so you can
        focus on the heavy lifting.
      </p>
      <div className="app-link-con">
        <a
          onClick={() => {
            // if (isMobile && isIOS) {
            //   window.location = "https://app.trainfitness.ai/HcEiINpgJvb";
            // } else {
            //   setJoinModalState(true);
            // }
            window.open("https://apps.apple.com/app/id1548577496", "_blank");
          }}
          className="cta-btn"
        >
          <img src={logoApple} alt="" />
          Join Train for Free
        </a>
      </div>
    </section>
  );
}

export default AppStore;
