import React, { useEffect } from "react";
import "./styes.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { BrowserRouter } from "react-router-dom";
import Router from "../Router/Router";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { useRecoilState, useSetRecoilState } from "recoil";
import { joinModalState, ouibouceHanderState } from "../../recoil/atoms";
import ouibounce from "ouibounce";

function App() {
  const setJoinModalState = useSetRecoilState(joinModalState);
  const [ouibouceHander, setOuibouceHander] =
    useRecoilState(ouibouceHanderState);

  useEffect(() => {
    if (!ouibouceHander) {
      const handlerRef = ouibounce(false, {
        aggressive: false,
        cookieExpire: 30,
        cookieName: "joinModal",
        callback: function () {
          setJoinModalState(true);
        },
      });
      setOuibouceHander(handlerRef);
    }

    return () => (ouibouceHander ? ouibouceHander.disable() : null);
  }, [ouibouceHander]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Router />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
