import React, { useEffect, useState } from "react";
import "./style.scss";
import classNames from "classnames";
import featureImage3 from "../../Images/stats.png";
import featureImage2 from "../../Images/repcount.png";
import featureImage1 from "../../Images/social.png";
import { Container } from "reactstrap";
import LazyLoad from "react-lazyload";
import ScrollTrigger from "react-scroll-trigger";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { joinModalState, ouibouceHanderState } from "../../recoil/atoms";

function Feature() {
  const setJoinModalState = useSetRecoilState(joinModalState);
  const ouibouceHander = useRecoilValue(ouibouceHanderState);
  const [changed, setChanged] = useState(false);
  const [currentFeature, setCurrentFeature] = useState(0);
  function onEnterViewport() {
    if (ouibouceHander) {
      ouibouceHander?.fire();
    }
  }
  const features = [
    {
      title: "Rep Counting",
      description:
        "AI algorithms automatically detect what exercise you are performing by monitoring the movement of your wrist",
      image: featureImage2,
      color: "#97ABF3",
    },
    {
      title: "Stats & Analytics",
      description:
        "Determine your weak points, monitor your progress and improve faster",
      image: featureImage3,
      color: "#FFE1BE",
    },
    {
      title: "Social",
      description:
        "Share workouts on your feed, challenge friends, and join the community",
      image: featureImage1,
      color: "#BCE1DD",
    },
  ];
  useEffect(() => {
    if (typeof window.scrollY === "undefined") {
      // don't work in IE
      return;
    }
    const handleScroll = () => {
      requestAnimationFrame(() => {
        const viewHeight = window.innerHeight;
        for (let i = 0; i < features.length; i++) {
          const featureItem = document.getElementsByClassName(
            "feature-item-" + i
          );
          const featureItemInner = document.getElementsByClassName(
            "feature-item-inner-" + i
          );
          const featureItemY =
            featureItem?.[0]?.getBoundingClientRect()?.top -
            viewHeight +
            featureItemInner[0]?.offsetHeight;
          if (featureItemY < 0) {
            setChanged(true);
            setCurrentFeature(i);
          }
        }
        const featureItem0 = document.getElementsByClassName("feature-item-0");
        const featureItem0Y =
          featureItem0?.[0]?.getBoundingClientRect()?.top -
          viewHeight +
          featureItem0?.[0]?.offsetHeight;
        const slickSlider = document.getElementsByClassName("slick-slider");
        const slickSliderY =
          slickSlider?.[0]?.getBoundingClientRect()?.top - viewHeight;
        if (featureItem0Y > 0 || slickSliderY < 0) {
          setChanged(false);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    //window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      //window.removeEventListener("resize", handleScroll);
    };
  });

  return (
    <>
      <Container>
        <section className="feature-section-sub">
          <p>
            Automatic exercise detection & rep counting - enabled using AI and
            your Apple Watch
          </p>
          <p className="subtext-note">
            *Apple Watch Only. Support for other platforms coming Spring 2023.
          </p>
          {/* <div className="join-the-wait-list">
            <a href="#">
              Join the Waitlist
              <svg
                width="19"
                height="12"
                viewBox="0 0 19 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.8677 5.33082L17.8668 5.33003L12.6335 0.966521C12.2415 0.639636 11.6073 0.640853 11.2171 0.969374C10.8269 1.29785 10.8284 1.82915 11.2204 2.15608L14.7341 5.08567H1.00155C0.448395 5.08567 0 5.46135 0 5.9248C0 6.38826 0.448395 6.76394 1.00155 6.76394H14.734L11.2205 9.69353C10.8284 10.0205 10.8269 10.5518 11.2171 10.8802C11.6074 11.2088 12.2416 11.2099 12.6336 10.8831L17.8669 6.51958L17.8678 6.51879C18.26 6.19077 18.2588 5.65775 17.8677 5.33082Z"
                  fill="#ffffff"
                />
              </svg>
            </a>
          </div> */}
        </section>
      </Container>

      <ScrollTrigger onEnter={onEnterViewport}>
        <section className="feature-section">
          <div className="feature-section-row">
            <div className="feature-section-left-col">
              <h2 className="feature-title">The Train Fitness experience:</h2>
              {features?.map((feature, key) => {
                return (
                  <div
                    key={key}
                    className={classNames(
                      "feature-item",
                      "feature-item-" + key,
                      {
                        activeFeature: currentFeature === key,
                      }
                    )}
                  >
                    <div className={classNames("feature-item-inner-" + key)}>
                      <h3 className="feature-item-1-headline">
                        <span style={{ backgroundColor: feature.color }} />{" "}
                        {feature.title}
                      </h3>
                      <p>{feature.description}</p>
                      <div
                        className="mobile-feature-image"
                        style={{ backgroundColor: feature.color }}
                      >
                        <LazyLoad>
                          <img src={feature.image} alt={feature.title} />
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="feature-section-right-col">
              <div
                className={classNames(
                  "sticky-top sticky-section d-flex justify-content-center align-self-center align-items-center",
                  "sticky-section-feature-" + currentFeature
                )}
                style={{
                  backgroundColor: changed
                    ? features[currentFeature].color
                    : "",
                }}
              >
                {features?.map((feature, key) => {
                  return (
                    <div
                      key={key}
                      className={classNames(
                        "feature-img",
                        "feature-img-" + key,
                        {
                          activeFeature: currentFeature === key,
                        }
                      )}
                    >
                      <img src={feature.image} alt={feature.title} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </ScrollTrigger>
    </>
  );
}

export default Feature;
