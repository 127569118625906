import { Container } from "reactstrap";

function Privacy() {
  return (
    <Container className="mt-5 mb-5">
      <h1>Our Privacy Commitment</h1>
      <div className="mt-3">
        <p>Last Updated: February 1st, 2024</p>

        <h4
          style={{
            fontWeight: "bold",
            marginTop: "2rem",
          }}
        >
          We are athletes too. Safeguarding our users data is core to Train
          Fitness.
        </h4>
        <p>
          At Train Fitness, protecting the privacy and safety of our users' data
          is not just a priority—it's ingrained in our organizational ethos.
          Every decision we make is guided by a commitment to this principle. We
          staunchly believe that personal data should never be exploited for any
          political or personal motives.
        </p>
        <p>
          Our data storage practices are designed to enhance user experience
          without compromising privacy. We only retain the essential data needed
          for a seamless experience. We uphold the belief in delivering a
          superior product while maintaining utmost respect for our users.
        </p>
        <h5 style={{
            marginTop: "1.5rem",
            fontWeight: "bold",
        }}>
          The foundations of Train Fitness rest on the following principles:
        </h5>
        <ol style={{
            // paddingLeft: "1.25rem", 
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
        }}>
          <li>
            <p>
              Your data belongs to you, not us. Delete anything at your
              discretion.
            </p>
          </li>
          <li>
            <p>
              We collect and store as little data as required. When data storage
              is required, we anonymize data when possible to minimize
              connecting data back to specific end users.
            </p>
          </li>
          <li>
          <p>

            We will never trade, sell, or otherwise distribute your personal or
            workout data. It’s not ours to sell after all.
            </p>
          </li>
          <li>
            <p>
              Third-party services do not have access to personally identifiable
              information (PII)
            </p>
          </li>
          <li>
            <p>
            Any changes in the data we store will be communicated transparently,
            providing clear insights into the reasons behind such modifications.
            </p>
          </li>
          <li>
            <p>
              Your consent is paramount. Data flows through{" "}
              <a
                href="https://www.apple.com/ca/ios/health/"
                target="_blank"
                rel="noopener"
              >
                Apple Health
              </a>{" "}
              to ensure you’re consenting to data sharing. We will always seek
              your permission when accessing your data.
            </p>
          </li>
        </ol>
        <p>
          For additional details on our commitment to privacy and security,
          peruse our comprehensive FAQ section.
        </p>
        <p>
          Questions regarding our privacy stance? Reach out to us at
            <a href="mailto:hello@trainfitness.ai" 
            style={{
                color: "#007bff",
                textDecoration: "none",
                paddingLeft: "0.5rem",
                // paddingRight: "0.5rem",
            }}>
            hello@trainfitness.ai
            </a>
           , and we'll gladly address your concerns.
        </p>
        <p>
          We all deserve to benefit from the powers of AI without the risk of
          our data being compromised. From one athlete to another, may your
          lifts be strong, and secure.
        </p>
        <div>
          <img src="/andrewSignV2.png" alt="Andrew Just"  style={{height: "50px"}}/>
          <div>Andrew Just</div>
          <div>CEO, Train Fitness</div>
        </div>
      </div>
    </Container>
  );
}

export default Privacy;
