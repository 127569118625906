import React from "react";
import "./style.scss";
import BGImageDesktop from "../../Images/join_team_desktop.jpg";
import BGImageMobile from "../../Images/join_team_mobile.jpg";
import LazyLoad from "react-lazyload";

function JoinTheTeam() {
  const imageUrl = window.innerWidth >= 768 ? BGImageDesktop : BGImageMobile;
  return (
    <LazyLoad>
      <section
        className="join-the-team-section"
        style={{
          backgroundImage: "url(" + imageUrl + ")",
        }}
      >
        <h3 className="join-the-team-section-h">Join the Team</h3>
        <div className="app-link-con">
          <a
            href="https://angel.co/company/train-fitness"
            target="_blank"
            rel="noreferrer"
          >
            See Job Openings
            <svg
              width="19"
              height="12"
              viewBox="0 0 19 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.8677 5.33082L17.8668 5.33003L12.6335 0.966521C12.2415 0.639636 11.6073 0.640853 11.2171 0.969374C10.8269 1.29785 10.8284 1.82915 11.2204 2.15608L14.7341 5.08567H1.00155C0.448395 5.08567 0 5.46135 0 5.9248C0 6.38826 0.448395 6.76394 1.00155 6.76394H14.734L11.2205 9.69353C10.8284 10.0205 10.8269 10.5518 11.2171 10.8802C11.6074 11.2088 12.2416 11.2099 12.6336 10.8831L17.8669 6.51958L17.8678 6.51879C18.26 6.19077 18.2588 5.65775 17.8677 5.33082Z"
                fill="#2D2D2D"
              />
            </svg>
          </a>
        </div>
      </section>
    </LazyLoad>
  );
}

export default JoinTheTeam;
