import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import Slider from "react-slick";
import IMG_1 from "../../Images/friends.jpg";
import IMG_4 from "../../Images/Waiting.jpg";
import IMG_5 from "../../Images/set.jpg";
import IMG_8 from "../../Images/inside.jpg";
import IMG_9 from "../../Images/pre-pranned.jpg";
import IMG_10 from "../../Images/details.jpg";
import IMG_11 from "../../Images/exercise.jpg";
import IMG_12 from "../../Images/prof-stats.jpg";
import LazyLoad from "react-lazyload";

function Community() {
  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className="overflow-hidden">
      <section className="community-section">
        <h3 className="community-section-h">
          Over 100 exercises fully tracked using the motion of your Apple Watch.
        </h3>
        <p className="community-section-t">
          Join thousands of other gym-enthusiasts using AI to improve the way
          they work out. Join the community now!
        </p>
        <LazyLoad>
          <Slider {...settings}>
            <img src={IMG_11} alt="" />
            <img src={IMG_1} alt="" />
            <img src={IMG_12} alt="" />
            <img src={IMG_9} alt="" />
            {/* <img src={IMG_3} alt="" /> */}
            {/* <img src={IMG_2} alt="" /> */}
            <img src={IMG_10} alt="" />
            <img src={IMG_4} alt="" />
            <img src={IMG_5} alt="" />
            <img src={IMG_8} alt="" />
          </Slider>
        </LazyLoad>
        {/* <div className="join-the-wait-list">
          <a href="#">
            Join the Waitlist
            <svg
              width="19"
              height="12"
              viewBox="0 0 19 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.8677 5.33082L17.8668 5.33003L12.6335 0.966521C12.2415 0.639636 11.6073 0.640853 11.2171 0.969374C10.8269 1.29785 10.8284 1.82915 11.2204 2.15608L14.7341 5.08567H1.00155C0.448395 5.08567 0 5.46135 0 5.9248C0 6.38826 0.448395 6.76394 1.00155 6.76394H14.734L11.2205 9.69353C10.8284 10.0205 10.8269 10.5518 11.2171 10.8802C11.6074 11.2088 12.2416 11.2099 12.6336 10.8831L17.8669 6.51958L17.8678 6.51879C18.26 6.19077 18.2588 5.65775 17.8677 5.33082Z"
                fill="#ffffff"
              />
            </svg>
          </a>
        </div> */}
      </section>
    </div>
  );
}

export default Community;
