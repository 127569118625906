import React, { useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import poster from "../../Images/mobile-splash-screen.jpg";
import heroVideo from "../../Videos/trainfitness.mp4";
import { init } from "ityped";
import "./style.scss";
import wavingHand from "../../Images/waving-hand-sign_1f44b.png";

function Hero() {
  useEffect(() => {
    const myElement = document.querySelector("#typing-animation");
    init(myElement, {
      typeSpeed: 50,
      backSpeed: 15,
      startDelay: 100,
      backDelay: 2000,
      loop: true,
      showCursor: false,
      placeholder: false,
      disableBackTyping: false,
      strings: [
        "Automatic\nRep Tracking.",
        "Motion\nTracked\nWorkouts.",
        "Hands-Free\nExercise\nDetection.",
        "Your\nApple Watch\nTrainer.",
      ],
    });
  }, []);
  return (
    <Container className="">
      <section className="hero-section">
        <Row className="justify-content-center align-self-center align-items-center">
          <Col md={6} className="hero-section-left">
            <div className="iphone">
              <div className="iphone__inner">
                <div className="iphone__gif">
                  {
                    <video
                      className="hero-video"
                      muted
                      autoPlay
                      loop
                      poster={poster}
                      preload="none"
                      playsInline
                    >
                      <source src={heroVideo} type="video/mp4" />
                    </video>
                  }
                  {/*<img src={poster} alt="" />*/}
                </div>
                <div className="iphone-header-button">
                  <div className="iphone-header-button__left">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="iphone-header-button__right">
                    <span />
                  </div>
                </div>
                <div className="iphone-header">
                  <div className="iphone-header__inner">
                    <div className="iphone-header__item" />
                    <div className="iphone-header-circle">
                      <div className="iphone-header-circle__inner">
                        <div className="iphone-header-circle__item" />
                        <div className="iphone-header-circle__item" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} className="hero-section-right">
            <h1 className="hero-title">
              Say hello {` `}
              <img src={wavingHand} />
              {` `}
              to
            </h1>
            <div className="typing-animation-con">
              <div id="typing-animation" />
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
}

export default Hero;
