import Hero from "../Hero/Hero";
import Feature from "../Feature/Feature";
import Community from "../Community/Community";
import AppStore from "../AppStore/AppStore";
import JoinTheTeam from "../JoinTheTeam/JoinTheTeam";
import "./style.scss";
import React from "react";
import JoinModal from "../JoinModal/JoinModal";

function Home() {
  return (
    <>
      <JoinModal />
      <Hero />
      <Feature />
      <Community />
      <AppStore />
      <JoinTheTeam />
    </>
  );
}

export default Home;
