import { Container } from "reactstrap";
import "./style.scss";

function PrivacyPolicy() {
  return (
    <Container className="mt-5 mb-5 privacy_page">
      <h1>PRIVACY NOTICE</h1>
      <div className="mt-3">
        <p>
          <strong>
            Last updated August 1<sup>st</sup>, 2021
          </strong>
        </p>
        <p>
          Thank you for choosing to be part of our community at Train Fitness.
          ("<b>Company,</b>" "<b>we,</b>" "<b>us,</b>" or "<b>our</b>"). We are
          committed to protecting your personal information and your right to
          privacy. If you have any questions or concerns about this privacy
          notice or our practices with regard to your personal information,
          please contact us at{" "}
          <a href="mailto:hello@trainfitness.ai">hello@trainfitness.ai</a>.
        </p>
        <p>
          The purpose of this privacy notice is to explain to you in the
          clearest way possible what information we collect, how we use it, and
          what rights you have in relation to it. If there are any terms in this
          privacy notice that you do not agree with, please discontinue use of
          our Services immediately.
        </p>
        <p>
          <strong>
            Please read this privacy notice carefully, as it will help you
            understand what we do with the information that we collect.
          </strong>
        </p>

        <h2>TABLE OF CONTENTS</h2>
        <ol className="toc">
          <li>
            <a href="#1">WHAT INFORMATION DO WE COLLECT?</a>
          </li>
          <li>
            <a href="#2">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
          </li>
          <li>
            <a href="#3">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
          </li>
          <li>
            <a href="#4">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
          </li>
          <li>
            <a href="#5">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a>
          </li>
          <li>
            <a href="#6">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#7">DO WE COLLECT INFORMATION FROM MINORS?</a>
          </li>
          <li>
            <a href="#8">WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </li>
          <li>
            <a href="#9">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          </li>
          <li>
            <a href="#10">
              {" "}
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#11"> DO WE MAKE UPDATES TO THIS NOTICE?</a>
          </li>
          <li>
            <a href="#12"> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          </li>
          <li>
            <a href="#13">
              {" "}
              HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
            </a>
          </li>
        </ol>

        <div id="1">
          <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
          <h3>Personal information you disclose to us</h3>
          <p className="font-italic">
            <strong>In Short:</strong> We collect personal information that you
            provide to us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the (such as by posting messages in our
            online forums or entering competitions, contests or giveaways) or
            otherwise when you contact us.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interactions with us, the choices you make and the products and
            features you use. The personal information we collect may include
            the following:
          </p>

          <p>
            <strong>Social Media Login Data.</strong> We may provide you with
            the option to register with us using your existing social media
            account details, like your Facebook, Twitter or other social media
            account. If you choose to register in this way, we will collect the
            information described in the section called "HOW DO WE HANDLE YOUR
            SOCIAL LOGINS?" below.
          </p>

          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </p>

          <h3>Information automatically collected</h3>

          <p className="font-italic">
            <strong>In Short:</strong> Some information — such as your Internet
            Protocol (IP) address and/or browser and device characteristics — is
            collected automatically when you use our app.
          </p>

          <p>
            We automatically collect certain information when you visit, use or
            navigate the app. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            how and when you use our and other technical information. This
            information is primarily needed to maintain the security and
            operation of our app, and for our internal analytics and reporting
            purposes.
          </p>

          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
        </div>
        <div id="2">
          <h2>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
          <p className="font-italic">
            <strong>In Short:</strong> We only share information with your
            consent, to comply with laws, to provide you with services, to
            protect your rights, or to fulfill business obligations.
          </p>

          <p>
            We may process or share your data that we hold based on the
            following legal basis:
          </p>
          <p>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </p>
          <ul>
            <li>
              <p>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </p>
            </li>
            <li>
              <p>
                <strong>Affiliates.</strong> We may share your information with
                our affiliates, in which case we will require those affiliates
                to honor this privacy notice. Affiliates include our parent
                company and any subsidiaries, joint venture partners or other
                companies that we control or that are under common control with
                us.
              </p>
            </li>
            <li>
              <p>
                <strong>Business Partners.</strong> We may share your
                information with our business partners to offer you certain
                products, services or promotions.
              </p>
            </li>
            <li>
              <p>
                <strong>Other Users.</strong> When you share personal
                information or otherwise interact with public areas of the app,
                such personal information may be viewed by all users and may be
                publicly made available outside the in perpetuity. If you
                interact with other users of our and register for our through a
                social network (such as Facebook), your contacts on the social
                network will see your name, profile photo, and descriptions of
                your activity. Similarly, other users will be able to view
                descriptions of your activity, communicate with you within our
                app, and view your profile.
              </p>
            </li>
          </ul>
        </div>

        <div id="3">
          <h2> 3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
          <p className="font-italic">
            <strong> In Short:</strong> We may use cookies and other tracking
            technologies to collect and store your information.
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
        </div>
        <div id="4">
          <h2>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

          <p className="font-italic">
            <strong> In Short:</strong> If you choose to register or log in to
            our services using a social media account, we may have access to
            certain information about you.
          </p>

          <p>
            Our offers you the ability to register and login using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, profile picture as well as other
            information you choose to make public on such social media platform.
          </p>

          <p>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant pages of our privacy policy. Please
            note that we do not control, and are not responsible for, other uses
            of your personal information by your third-party social media
            provider. We recommend that you review their privacy notice to
            understand how they collect, use and share your personal
            information, and how you can set your privacy preferences on their
            sites and apps.
          </p>
        </div>

        <div id="5">
          <h2>5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
          <p className="font-italic">
            <strong>In Short:</strong> We may transfer, store, and process your
            information in countries other than your own.
          </p>

          <p>
            Our servers are located in. If you are accessing our from outside,
            please be aware that your information may be transferred to, stored,
            and processed by us in our facilities and by those third parties
            with whom we may share your personal information (see "WILL YOUR
            INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.
          </p>

          <p>
            If you are a resident in the European Economic Area (EEA) or United
            Kingdom (UK), then these countries may not necessarily have data
            protection laws or other similar laws as comprehensive as those in
            your country. We will however take all necessary measures to protect
            your personal information in accordance with this privacy notice and
            applicable law.
          </p>
        </div>
        <div id="6">
          <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

          <p className="font-italic">
            <strong>In Short: </strong> We keep your information for as long as
            necessary to fulfill the purposes outlined in this privacy notice
            unless otherwise required by law.
          </p>

          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements).
          </p>

          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </div>
        <div id="7">
          <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>

          <p className="font-italic">
            <strong> In Short:</strong> We do not knowingly collect data from or
            market to children under 18 years of age.
          </p>

          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the , you represent that you are at least 18
            or that you are the parent or guardian of such a minor and consent
            to such minor dependent’s use of the . If we learn that personal
            information from users less than 18 years of age has been collected,
            we will deactivate the account and take reasonable measures to
            promptly delete such data from our records. If you become aware of
            any data we may have collected from children under age 18, please
            contact us at
            <a href="mailto:hello@trainfitness.ai">hello@trainfitness.ai</a>.
          </p>
        </div>
        <div id="8">
          <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

          <p className="font-italic">
            <strong>In Short:</strong> You may review, change, or terminate your
            account at any time.
          </p>

          <p>
            If you are a resident in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:{" "}
            <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            .
          </p>

          <p>
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:{" "}
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
            .
          </p>

          <h3>Account Information</h3>

          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can do so through your
            account preferences, or email us at hello@trainfitness.ai.
          </p>

          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with applicable legal
            requirements.
          </p>

          <p>
            Opting out of email marketing: You can unsubscribe from our
            marketing email list at any time by clicking on the unsubscribe link
            in the emails that we send or by contacting us using the details
            provided below. You will then be removed from the marketing email
            list — however, we may still communicate with you, for example to
            send you service-related emails that are necessary for the
            administration and use of your account, to respond to service
            requests, or for other non-marketing purposes.
          </p>
        </div>
        <div id="9">
          <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </div>
        <div id="10">
          <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

          <p className="font-italic">
            <strong>In Short:</strong> Yes, if you are a resident of California,
            you are granted specific rights regarding access to your personal
            information.
          </p>

          <p>
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>

          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with Train Fitness, you have the right to request
            removal of unwanted data that you publicly post on the app. To
            request removal of such data, please contact us using the contact
            information provided below, and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the app, but
            please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>
        </div>

        <div id="11">
          <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

          <p className="font-italic">
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </p>

          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>

        <div id="12">
          <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

          <p>
            If you have questions or comments about this notice, you may email
            us at{" "}
            <a href="mailto:hello@trainfitness.ai">hello@trainfitness.ai</a>.
          </p>
        </div>
        <div id="13">
          <h2>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h2>

          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please email us at{" "}
            <a href="mailto:hello@trainfitness.ai">hello@trainfitness.ai</a>
          </p>

          <p>
            We will remove all data pertaining to your account, if you as a user
            decide to delete it. The way to delete your data is by opening the
            Train Fitness App and going to your Profile Page. Then proceed to
            your user settings (top right corner) and tap Delete my account. All
            data from your account will be deleted. Be advised that this is
            permanent and you will not be able to retrieve any data.
          </p>
        </div>
      </div>
    </Container>
  );
}

export default PrivacyPolicy;
