import { Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
import React from "react";
import Terms from "../Terms/Terms";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Privacy from "../Terms/PrivacyCommitment";

function Router() {
  return (
    <Switch>
      <Route path="/privacy">
        <PrivacyPolicy />
      </Route>
      <Route path="/terms">
        <Terms />
      </Route>
      <Route path="/privacycommitment">
        <Privacy />
      </Route>
      
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}

export default Router;
